import autoBind from 'auto-bind'
import _ from 'lodash'

class QueryService {
  constructor (router) {
    autoBind(this)
    this.$router = router
  }

  getQuery (field = false, defaultValue = undefined) {
    if (field) {
      return _.cloneDeep(_.get(this.$router, `history.current.query.${field}`, defaultValue))
    }
    return _.cloneDeep(_.get(this.$router, `history.current.query`, {}))
  }

  cleanQuery (query) {
    const filter = _.get(query, 'filter', 'team')
    const mapping = {
      'team': ['filter', 'department', 'team', 'mode', 'start', 'end'],
      'resource': ['filter', 'employee', 'mode', 'start', 'end'],
      'project': ['filter', 'project', 'department', 'start', 'end']
    }
    const whitelist = _.get(mapping, filter, [])
    const keys = _.keys(query)
    _.forEach(keys, k => {
      if (!_.includes(whitelist, k)) {
        delete query[k]
      }
    })
    const params = _.get(mapping, filter, [])
    _.forEach(params, k => {
      if (_.get(query, k, false) === false) {
        query[k] = _.get(this, k, '')
      }
    })
  }

  updateQueryForKey (key, value, path) {
    const query = this.getQuery()
    query[key] = value
    this.cleanQuery(query)
    this.updateQuery(query, path)
  }

  updateQueryForKeys (keyValues, path) {
    const query = this.getQuery()
    _.forEach(keyValues, (value, key) => {
      query[key] = value
    })
    this.cleanQuery(query)
    this.updateQuery(query, path)
  }

  updateQuery (query = false, path) {
    query = query || this.getQuery()
    this.cleanQuery(query)
    this.$router.replace({
      path: path || this.$router.history.current.path,
      query
    }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }
}

export default {
  install: (Vue, router) => {
    Vue.prototype.$queryService = new QueryService(router)
  }
}
