<template>
  <div id="app">
    <loading :active.sync="isLoading"
             :is-full-page="true"
    >
      <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" :width="64" :height="64" stroke="#000">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </loading>
    <template v-if="isSignedIn">
      <div class="navigation">
        <router-link to="/resources">📋 Resources View</router-link>
        <router-link to="/calendar">📅 Calendar View</router-link>
        <div class="right">
          <div v-shortkey.once="['esc']" :class="{displayed: isSearchPanelOpened}" class="search-panel" @shortkey="onCloseSearch($event)">
            <div class="content">
              <div v-if="isSearchFocused && (!searchText || searchText.length <= 0)" class="results">
                <div class="result no-cursor">
                  Search team, resource or projects...
                </div>
              </div>
              <div v-else class="results">
                <template v-if="searchResults && searchResults.length > 0">
                  <div v-for="(item, index) in searchResults" :key="index" class="result" :class="{'selected': searchIndex == index}">
                    <div v-if="item.type === 'department'" class="icon">
                      <svg id="Capa_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           width="122.699px" height="122.699px" viewBox="0 0 122.699 122.699" style="enable-background:new 0 0 122.699 122.699;"
                           xml:space="preserve"
                      >
                        <g>
                          <circle cx="19.5" cy="12.2" r="12.1" />
                          <path d="M6,66.699h1.2v24c0,3.301,2.7,6,6,6h12.6c3.3,0,6-2.699,6-6V89.3c-1.1-2.101-1.8-4.5-1.8-7v-31.4c0-6.1,3.7-11.4,9-13.7 v-2.4c0-3.3-2.7-6-6-6H6c-3.3,0-6,2.7-6,6v25.9C0,64,2.6,66.699,6,66.699z" />
                          <circle cx="103.3" cy="12.2" r="12.1" />
                          <path d="M83.699,34.7v2.4c5.301,2.3,9,7.6,9,13.7v31.3c0,2.5-0.6,4.9-1.799,7v1.4c0,3.3,2.699,6,6,6h12.6c3.3,0,6-2.7,6-6v-24 h1.199c3.301,0,6-2.7,6-6V34.7c0-3.3-2.699-6-6-6h-27C86.4,28.7,83.699,31.399,83.699,34.7z" />
                          <path d="M39.1,50.899L39.1,50.899v9.8v21.6c0,3.3,2.7,6,6,6h2.3v28.3c0,3.3,2.7,6,6,6h16.1c3.3,0,6-2.7,6-6v-28.4h2.3 c3.3,0,6-2.699,6-6V60.7v-9.8l0,0c0-3.3-2.7-6-6-6H45.1C41.7,44.899,39.1,47.6,39.1,50.899z" />
                          <circle cx="61.4" cy="26" r="13.9" />
                        </g>
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                      </svg>
                    </div>
                    <div v-if="item.type === 'project'" class="icon">
                      <svg id="Capa_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           viewBox="0 0 220 220" style="enable-background:new 0 0 220 220;" xml:space="preserve"
                      >
                        <path d="M211.5,15.022C211.5,6.726,204.774,0,196.478,0H23.522C15.226,0,8.5,6.726,8.5,15.022v189.955 C8.5,213.274,15.226,220,23.522,220h172.955c8.297,0,15.022-6.726,15.022-15.022V15.022z M88.5,199h-49v-45h49V199z M88.5,132h-49 V88h49V132z M88.5,66h-49V21h49V66z M144.803,199.63l-26.306-26.306l11.205-11.205l15.101,15.102l23.65-23.65l11.205,11.205 L144.803,199.63z M144.803,133.03l-26.306-26.306l11.205-11.205l15.101,15.101l23.65-23.65l11.205,11.205L144.803,133.03z M144.803,66.429l-26.306-26.306l11.205-11.205l15.101,15.101l23.65-23.65l11.205,11.205L144.803,66.429z" />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                      </svg>
                    </div>
                    <div v-if="item.type === 'resource'" class="icon">
                      <svg width="512px" height="512px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M250.882 22.802c-23.366 3.035-44.553 30.444-44.553 65.935 0 19.558 6.771 36.856 16.695 48.815l11.84 14.263-18.217 3.424c-12.9 2.425-22.358 9.24-30.443 20.336-8.085 11.097-14.266 26.558-18.598 44.375-7.843 32.28-9.568 71.693-9.842 106.436h42.868l11.771 157.836c29.894 6.748 61.811 6.51 90.602.025l10.414-157.86h40.816c-.027-35.169-.477-75.126-7.584-107.65-3.918-17.934-9.858-33.372-18.04-44.343-8.185-10.97-18.08-17.745-32.563-19.989l-18.592-2.88 11.736-14.704c9.495-11.897 15.932-28.997 15.932-48.082 0-37.838-23.655-65.844-49.399-65.844z" /></svg>
                    </div>
                    <div class="label" @click="searchIndex = index; interactiveSearch({srcKey: 'enter', isClicked: true})" v-html="item.label" />
                  </div>
                </template>
                <div v-if="(!searchResults || searchResults.length == 0) && searchText && searchText.length > 0" class="result no-cursor">
                  No result found.
                </div>
              </div>
            </div>
          </div>
          <div class="search">
            <input id="search" ref="search" v-model="searchText" v-shortkey="{enter: ['enter'], up: ['arrowup'], down: ['arrowdown'], open: ['/']}" autocomplete="off"
                   autocorrect="off" spellcheck="false" type="search" placeholder="Search" value=""
                   @focus="isSearchFocused = true" @blur="isSearchFocused = false" @shortkey="interactiveSearch" @input="doSearch()"
            >
            <span class="icon">
              <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.2269 17.4164 13.8175 16.4356 15.0852L20.3769 19.0953C20.764 19.4892 20.7586 20.1223 20.3647 20.5095C19.9708 20.8966 19.3376 20.8911 18.9505 20.4972L15.0129 16.4909C13.7572 17.4383 12.1942 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="currentColor" />
              </svg>
            </span>
          </div>
          <div class="user">
            <img :src="user.picture">
          </div>
          <a class="logout" @click="logout">
            Logout
          </a>
        </div>
      </div>
      <router-view class="router-view" />
    </template>
    <div v-else class="sign-in">
      <div class="logo">
        <svg viewBox="0 0 139 37" version="1.1" fill="none">
          <path d="M32.4925 24.5802L33.5164 21.358L34.5156 24.5802H32.4925ZM103.3 18C103.3 13.8025 106.692 10.3951 110.899 10.3951C115.093 10.3951 118.498 13.8025 118.498 18C118.498 22.1975 115.093 25.6049 110.899 25.6049C106.692 25.6049 103.3 22.1975 103.3 18ZM81.0587 24.5802L82.0702 21.358L83.1064 24.5926L81.0587 24.5802ZM0 0H139V37H0V0ZM6.4393 30.6296H11.9411V6.37037H6.4393V30.6296ZM46.7157 14.1235V19.0123H55.4002C54.9067 22.7284 51.7241 25.6049 47.863 25.6049C43.6688 25.6049 40.2764 22.1975 40.2764 18C40.2764 13.8025 43.6811 10.3951 47.863 10.3951C49.738 10.3951 51.465 11.0741 52.7973 12.1975L56.054 7.96296C53.8088 6.14815 50.9593 5.06173 47.863 5.06173C42.5832 5.06173 38.019 8.24691 35.9959 12.8272L33.4177 4.79012L28.3724 20.7654L28.36 4.83951L20.8845 14.5062C19.6271 12.9648 18.4079 11.3382 17.201 9.72822C15.9333 8.03702 14.6793 6.36405 13.409 4.82716V30.6173H18.7998V20.642L20.8845 23.2963L23.1297 20.5432L23.1543 30.6173H30.6052L31.2343 28.6049H35.8355L36.4893 30.6173H41.7074L41.214 29.0617C43.163 30.2469 45.4452 30.9136 47.8876 30.9136C52.4642 30.9136 56.4857 28.5432 58.7802 24.9506V30.6296H64.2203V6.40741H58.7802V14.1235H46.7157ZM127.602 14.3704L120.866 4.4321V9.77778C118.498 6.90123 114.908 5.07407 110.887 5.07407C106.952 5.07407 103.423 6.83951 101.055 9.61728H100.919V6.37037H85.2158V10.9877H87.609L87.6337 22.4938C87.572 22.4568 81.9962 4.76543 81.9962 4.76543L77.5676 18.7901L77.5553 6.37037H72.0535L72.0658 14.1111L65.7252 4.83951V30.6296H71.1283L71.153 23.4198L75.9886 30.6296H79.1343L79.9238 28.1481H84.229L85.0308 30.6296H93.0861V10.9877H95.5039V30.6296H100.981V26.3086C103.349 29.1358 106.902 30.9383 110.887 30.9383C114.945 30.9383 118.572 29.0617 120.94 26.1358V30.6296H126.356V22.1728L132.795 31.7037V6.37037H127.626L127.602 14.3704Z" fill="currentColor" />
          <path d="M32.4925 24.5802L33.5164 21.358L34.5156 24.5802H32.4925ZM103.3 18C103.3 13.8025 106.692 10.3951 110.899 10.3951C115.093 10.3951 118.498 13.8025 118.498 18C118.498 22.1975 115.093 25.6049 110.899 25.6049C106.692 25.6049 103.3 22.1975 103.3 18ZM81.0587 24.5802L82.0702 21.358L83.1064 24.5926L81.0587 24.5802ZM0 0H139V37H0V0ZM6.4393 30.6296H11.9411V6.37037H6.4393V30.6296ZM46.7157 14.1235V19.0123H55.4002C54.9067 22.7284 51.7241 25.6049 47.863 25.6049C43.6688 25.6049 40.2764 22.1975 40.2764 18C40.2764 13.8025 43.6811 10.3951 47.863 10.3951C49.738 10.3951 51.465 11.0741 52.7973 12.1975L56.054 7.96296C53.8088 6.14815 50.9593 5.06173 47.863 5.06173C42.5832 5.06173 38.019 8.24691 35.9959 12.8272L33.4177 4.79012L28.3724 20.7654L28.36 4.83951L20.8845 14.5062C19.6271 12.9648 18.4079 11.3382 17.201 9.72822C15.9333 8.03702 14.6793 6.36405 13.409 4.82716V30.6173H18.7998V20.642L20.8845 23.2963L23.1297 20.5432L23.1543 30.6173H30.6052L31.2343 28.6049H35.8355L36.4893 30.6173H41.7074L41.214 29.0617C43.163 30.2469 45.4452 30.9136 47.8876 30.9136C52.4642 30.9136 56.4857 28.5432 58.7802 24.9506V30.6296H64.2203V6.40741H58.7802V14.1235H46.7157ZM127.602 14.3704L120.866 4.4321V9.77778C118.498 6.90123 114.908 5.07407 110.887 5.07407C106.952 5.07407 103.423 6.83951 101.055 9.61728H100.919V6.37037H85.2158V10.9877H87.609L87.6337 22.4938C87.572 22.4568 81.9962 4.76543 81.9962 4.76543L77.5676 18.7901L77.5553 6.37037H72.0535L72.0658 14.1111L65.7252 4.83951V30.6296H71.1283L71.153 23.4198L75.9886 30.6296H79.1343L79.9238 28.1481H84.229L85.0308 30.6296H93.0861V10.9877H95.5039V30.6296H100.981V26.3086C103.349 29.1358 106.902 30.9383 110.887 30.9383C114.945 30.9383 118.572 29.0617 120.94 26.1358V30.6296H126.356V22.1728L132.795 31.7037V6.37037H127.626L127.602 14.3704Z" stroke="currentColor" />
        </svg>
      </div>
      <div :class="{ blink: gapiLoading }" class="google-sigin">
        <div id="google-btn" class="g_id_signin" />
      </div>
      <div v-if="gapiError" class="error">
        {{ gapiError }}
      </div>
    </div>
  </div>
</template>

<script >
import _ from 'lodash'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import GanttPro from './services/GanttProService'
import fuzzysort from 'fuzzysort'

export default {
  name: 'App',
  components: {
    loading: Loading
  },
  data () {
    return {
      searchText: '',
      searchIndex: 0,
      isSearchFocused: false,
      isLoading: false,
      gapiError: false,
      gapi: null,
      gapiLoading: true,
      searchResults: [],
      auth2: null,
      lastActive: +new Date(),
      isSignedIn: false
    }
  },
  computed: {
    user: () => GanttPro.user,
    isSearchPanelOpened: function () {
      return this.isSearchFocused || _.get(this.searchText, 'length', 0) > 0
    }
  },
  mounted () {
    window.login = this.login
    window.onblur = this.onBlur
    window.onfocus = this.onFocus
    this.googleAuth()
    GanttPro.events.on('loginStatus', statusCode => {
      if (statusCode >= 400) {
        console.error('loginStatus', statusCode)
        console.error('loginStatus: will autologout and ask to login again')
        this.isSignedIn = false
        this.$cookies.remove('payload')
        this.googleAuth()
      }
    })
  },
  methods: {
    onBlur () {
      this.lastActive = +new Date()
    },
    onFocus () {
      const duration = 10 * 60 * 60 * 1000
      if (this.lastActive + duration <= +new Date()) {
        console.warn('force cache refresh')
        window.location.reload(true)
      }
    },
    async doSearch () {
      this.searchResults = []
      this.searchIndex = 0
      this.departments = _.get(GanttPro, 'departments', [])
      this.projects = _.get(GanttPro, 'projects', [])
      // this.projects = _.filter(GanttPro, p => p.projectId !== 'out-of-office')
      this.employees = _.get(GanttPro, 'employees', [])
      let results = []
      _.forEach(this.departments, d => {
        if (d.isOverview) {
          results.push({
            value: d.name,
            filter: 'team',
            department: d.id,
            mode: 'overview',
            type: 'department'
          })
        } else if (d.isExternal) {
          results.push({
            value: d.name + ' - Per Tasks',
            filter: 'team',
            department: d.id,
            mode: 'tasks',
            type: 'department'
          })
          results.push({
            value: d.name + ' - Per Project',
            filter: 'team',
            department: d.id,
            mode: 'overview',
            type: 'department'
          })
        } else {
          results.push({
            filter: 'team',
            department: d.id,
            team: 'all',
            value: d.name + ' - All - Per Tasks',
            mode: 'tasks',
            type: 'department'
          })
          results.push({
            filter: 'team',
            department: d.id,
            team: 'all',
            value: d.name + ' - All - Per Project',
            mode: 'overview',
            type: 'department'
          })
          _.forEach(d.teams, t => {
            results.push({
              filter: 'team',
              department: d.id,
              team: t.id,
              value: d.name + ' - ' + t.name + ' - Per Tasks',
              mode: 'tasks',
              type: 'department'
            })
            results.push({
              filter: 'team',
              department: d.id,
              team: t.id,
              value: d.name + ' - ' + t.name + ' - Per Project',
              mode: 'overview',
              type: 'department'
            })
          })
        }
      })
      _.forEach(this.projects, p => {
        _.forEach(this.departments, d => {
          results.push({
            filter: 'project',
            value: p.name + ' - ' + d.name,
            type: 'project',
            project: p.id,
            department: d.id
          })
        })
      })
      _.forEach(this.employees, e => {
        results.push({
          value: e.name + ' - Per Tasks',
          type: 'resource',
          mode: 'tasks',
          filter: 'resource',
          employee: e.id
        })
        results.push({
          value: e.name + ' - Per Project',
          type: 'resource',
          mode: 'overview',
          filter: 'resource',
          employee: e.id
        })
      })
      results = _.uniqBy(results, 'value')
      const fuseResults = fuzzysort.go(_.toLower(this.searchText), results, {keys: ['value']})
      _.forEach(fuseResults, item => {
        const index = _.indexOf(results, _.get(item, 'obj', {}))
        if (index > -1) {
          const resultItem = _.get(results, index, false)
          const highlightItem = _.get(item, '[0]', false)
          if (resultItem !== false && highlightItem !== false) {
            resultItem.label = fuzzysort.highlight(highlightItem)
            this.searchResults.push(resultItem)
          }
        }
      })
      this.searchResults = _.slice(this.searchResults, 0, 10)
    },
    async onCloseSearch ($event) {
      this.searchText = ''
      this.searchIndex = 0
      this.searchResults = []
      if (this.$refs['search']) {
        this.$refs['search'].blur()
      }
    },
    async interactiveSearch (event) {
      const action = _.get(event, 'srcKey', false)
      if (action !== false) {
        if (action === 'up') {
          this.searchIndex = this.searchIndex - 1
          if (this.searchIndex < 0) {
            this.searchIndex = 0
          }
        } else if (action === 'down') {
          this.searchIndex = this.searchIndex + 1
          if (this.searchIndex > (this.searchResults.length - 1)) {
            this.searchIndex = this.searchResults.length - 1
          }
        } else if (action === 'enter') {
          const isClicked = _.get(event, 'isClicked', false)
          if (!isClicked && !this.isSearchFocused) {
            return true
          }
          if (_.get(this.searchText, 'length', 0) > 0 && _.get(this.searchResults, 'length', 0) > 0) {
            const item = this.searchResults[this.searchIndex]
            if (item) {
              this.onCloseSearch()
              const query = {}
              console.warn('jump for', item)
              if (item.filter === 'team') {
                query.filter = item.filter
                query.department = item.department
                query.team = item.team
                query.mode = item.mode
              } else if (item.filter === 'resource') {
                query.filter = item.filter
                query.employee = item.employee
                query.mode = item.mode
              } else if (item.filter === 'project') {
                query.filter = item.filter
                query.project = item.project
                query.department = item.department
              } else {
                return
              }
              console.warn('jump to', query)
              this.$queryService.updateQueryForKeys(query, '/resources')
            }
          }
        } else if (action === 'open') {
          if (this.$refs['search']) {
            this.$refs['search'].focus()
          }
        }
      }
    },
    async loadGapiInsideDOM  () {
      return new Promise(resolve => {
        const element = document.getElementsByTagName('script')[0]
        const js = document.createElement('script')
        js.id = 'google-security-identify'
        js.src = '//accounts.google.com/gsi/client'
        js.async = true
        js.defer = true
        element.parentNode.insertBefore(js, element)
        js.onload = async () => {
          resolve(window.gapi)
        }
      })
    },
    async logout () {
      if (!this.gapi) {
        return
      }
      this.$cookies.remove('payload')
      this.isSignedIn = await GanttPro.setToken(false)
      this.gapi.accounts.id.disableAutoSelect()
    },
    login () {
      if (document.getElementById('credential_picker_container')) {
        console.debug('GAPI google already open.')
      }
      this.$cookies.remove('payload')
      if (!this.gapi) {
        return
      }
      this.gapi.accounts.id.prompt()
      console.debug('GAPI prompt triggered')
    },
    async userStatus (payload) {
      this.$cookies.set('payload', payload, '14d', null, null, true)
      this.isSignedIn = await GanttPro.setToken(payload)
    },
    async googleAuth () {
      if (!this.gapi) {
        console.debug('GAPI is loading...')
        this.gapi = await this.loadGapiInsideDOM()
        this.gapi = window.google
        console.debug('GAPI is loaded.')
      }
      console.debug('GAPI is init.')
      this.gapi.accounts.id.initialize({
        client_id: '394655438992-5gf05098cpff7bbge5qor30iq6t2qjt3.apps.googleusercontent.com',
        callback: this.userStatus
      })

      this.$nextTick(async () => {
        console.debug('GAPI is init.')
        console.debug('GAPI btn is rendering...')
        const parent = document.getElementById('google-btn')
        this.gapi.accounts.id.renderButton(parent, {
          type: 'standard',
          shape: 'circle',
          theme: 'filled_blue',
          text: 'signin_with',
          size: 'large',
          logo_alignment: 'left'
        })
        console.debug('GAPI btn is rendered.')
        let payload = this.$cookies.get('payload')
        try {
          await GanttPro.checkPayloadExpiration(payload)
          console.debug('GAPI got payload and not expired')
        } catch (error) {
          console.debug('GAPI payload is expired')
          payload = false
        }
        if (payload) {
          console.debug('GAPI will re-use old token')
          this.userStatus(payload)
          this.$nextTick(() => this.gapiLoading = false)
        } else {
          console.debug('GAPI prompt triggered')
          this.gapi.accounts.id.prompt((notification) => {
            this.$nextTick(() => this.gapiLoading = false)
            console.debug('GAPI: prompt notification:', notification)
            const reason = _.get(notification, 'j', '?')
            if (reason === 'opt_out_or_no_session') {
              this.gapi = false
              this.gapiError = 'Your google account is opt-out for one-tape or your browser doesn\'t allow sessions.'
            } else {
              this.gapiError = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
html, body, #app {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans', sans-serif;
  color: '#333';
  font-size: 12px;
  min-width: 1280px;
}

.blink {
  user-select: none;
  touch-action: none;
  pointer-events: none;
  animation: blinker 1.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker { to { opacity: 0; } }

.sign-in {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: #101011;
  color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-content: center;
  .logo {
    margin-top: 48px;
    margin-left: 96px;
    margin-bottom: 100px;
    width: 200px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .google-sigin {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .login {
    &:hover {
      opacity: 0.95;
    }
    &:focus, &:active {
      opacity: 0.85;
    }
    transition: opacity;
    opacity: 1;
    margin: 0 auto;
    cursor: pointer;
    outline: none;
    user-select: none;
    white-space: nowrap;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    background-color: #fff;
    color: #757575;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    width: 120px;
    .icon {
      width: 18px;
      height: 18px;
      padding: 8px;
    }
    .text {
      font-family: Roboto,arial,sans-serif;
      font-weight: 500;
      letter-spacing: .21px;
      margin-left: 20px;
      margin-right: 6px;
      font-size: 13px;
    }
  }
  .error {
    text-align: center;
    margin-top: 20px;
    color: #eb0000;
    font-style: oblique;
  }
}

* {
  outline: none;
}

.btn, select {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid #dadce0;
  border-radius: 4px;
  box-sizing: border-box;
  height: 36px;
  margin: 4px;
  &:first-child {
    margin-left: 0px;
  }

  &.has-today {
    background: #135de6;
    color: white;
    &:hover {
      background: #508eff;
    }
  }
}

.btn:hover, select:hover {
  background-color: #f1f3f4;
}

.btn:active, select:active {
  background-color: #e6e6e6;
  outline: none;
}

.btn:disabled, select:disabled {
  background-color: #f9f9f9;
  border: solid 1px #ddd;
  color: #bbb;
}

.search-panel {
  .results {
    user-select: none;
    .result {
      cursor: pointer;
      &.no-cursor {
        cursor: inherit;
      }
      padding: 4px 12px;
      display: flex;
      .icon {
        width: 20px;
        height: 10px;
        svg {
          height: 100%;
          width: auto;
        }
      }
      b {
        font-weight: normal;
        text-decoration: underline;
      }
      &:hover:not(.no-cursor),
      &.selected {
        color: #135de6;
        background: rgb(0 0 0 / 5%);
        &:hover {
          background: rgb(0 0 0 / 5%);
        }
        font-weight: normal;
        b {
          font-weight: normal;
        }
        svg {
          circle, path {
            fill: #135de6;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.router-view {
  padding: 8px;
}
.navigation {
  display: flex;
  flex: none;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #eaecef;
  transition: all .2s ease-in-out;
  .search-panel {
    z-index: 1000;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08);
    box-sizing: border-box;
    outline: 0;
    overflow: hidden;
    position: absolute;
    width: 600px;
    max-width: 97%;
    will-change: top, left;
    top: 48px;
    right: 4px;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out .25s;
    &.displayed {
      opacity: 1;
      pointer-events: inherit;
    }
  }
  .search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
      height: 20px;
      svg {
        path {
          fill: rgba(0, 0, 0, 0.35);
        }
        height: auto;
        width: 100%;
      }
    }
    input {
      &:focus {
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px solid rgba(0, 0, 0, 0.25);
      }
      transition: all .5s ease-in-out;
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.8);
      float: left;
      font-size: 12px;
      height: 32px;
      line-height: 19px;
      margin-left: 4px;
      margin-right: 4px;
      outline: none;
      padding-left: 30px;
      padding-right: 8px;
      transition: width 150ms;
      width: 250px;
    }
  }
  .user {
    img {
      border-radius: 30px;
      width: 30px;
      height: auto;
      margin: 4px 6px 4px 2px;
      }
  }
  .logout {
      border: 1px solid #eaecef;
  }
  .right {
    margin-left: auto;
    display: flex;
  }
  a {
    display: flex;
    align-items: center;
    flex: none;
    position: relative;
    padding: 0 20px;
    color: #696969;
    font-size: 12px;
    border: 1px solid #eaecef;
    border-left: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .3s ease-in-out;
    transform-origin: left bottom;
    font-weight: bold;
    text-decoration: none;

    &:first-child {
      border-left: 1px solid #eaecef;
    }

    &.router-link-exact-active, &:hover{
      color: #000000;
    }

    &.router-link-exact-active{
      border-bottom-color: #929292;
    }
  }
}
</style>
