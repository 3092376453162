import Vue from 'vue'
import vueShortkey from 'vue-shortkey'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import router from './router'
import queryService from './services/QueryService'

import App from './App.vue'

Vue.config.productionTip = false
Vue.use(queryService, router)
Vue.use(vueShortkey)
Vue.use(VueCookies)
Vue.use(VueRouter)
Vue.use(ElementUI, {
  locale
})
const init = async () => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}

init()
