import _ from 'lodash'
import autoBind from 'auto-bind'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import jwt from 'jsonwebtoken'
import { EventEmitter } from 'tiny-events'
import WebSocket from './WebSocketService'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

class GanttPro {
  constructor () {
    autoBind(this)
    this.user = false
    this.tokenExpirationTimer = false
    this.endpoint = window.origin + '/api/ganttpro'
    this.projects = []
    this.resources = []
    this.specialDays = []
    this.promises = {}
    this.token = false
    this.departments = []
    this.filteredEmployees = []
    this.apiFields = ['resources', 'departments', 'projects', 'employees', 'filteredEmployees', 'specialDays']
    this.events = new EventEmitter()
    this.webSocket = new WebSocket()

    axiosRetry(axios, { retries: Infinity,
      retryDelay: (retryCount) => {
        this.events.emit('has-error', true)
        return retryCount > 10 ? 10000 : 1000 * retryCount
      } })
    this.http = axios

    this.webSocket.events.on('onConnect', ws => {
      ws.authenticate(this.token)
    })
  }

  async fetchData (query) {
    try {
      console.log('Init fetching data...')
      _.each(this.apiFields, (field) => {
        _.set(this, field, [])
      })
      const data = await this.fetchDataForQuery(query)
      _.each(this.apiFields, (field) => {
        _.set(this, field, _.get(data, field, []))
      })
      console.log('Done fetching data.')
    } catch (error) {
      const statusCode = _.get(error, 'response.status', 0)
      console.error('Failed to fetch data:', { query, error, statusCode })
      if (statusCode >= 400 && statusCode < 500) {
        console.error('loginStatus', statusCode)
        console.error('loginStatus: will autologout and ask to login again')
        this.expireUser()
      }
      throw error
    }
  }

  async setToken (payload) {
    try {
      if (!payload) {
        throw new Error('no payload found')
      }
      const token = this.getTokenFromPayload(payload)
      const user = jwt.decode(token)
      if (_.get(user, 'hd', 'anonymous.com') !== 'imagination.com') {
        throw new Error('not from imagination')
      }
      this.user = user
      this.token = token
      axios.defaults.headers.common['Authorization'] = this.token
      await this.checkExpiration(this.token)
      this.events.emit('loginStatus', 200)
      this.webSocket.connect()
      clearInterval(this.tokenExpirationTimer)
      this.tokenExpirationTimer = setInterval(async () => {
        try {
          console.warn('Checking expiration of the token...')
          await this.checkExpiration(this.token)
        } catch (error) {
          console.error('Expiration is reached:', error)
          this.expireUser()
        }
      }, 60 * 1000)
      return this.user
    } catch (error) {
      console.error('Failed to set user:', error)
      this.expireUser(true)
    }
    return false
  }

  async expireUser (force = false) {
    try {
      if (force) {
        throw new Error('forced logout')
      }
      await this.checkExpiration(this.token)
    } catch (error) {
      console.debug('Triggering user expiration...')
      clearInterval(this.tokenExpirationTimer)
      this.user = false
      this.token = false
      this.webSocket.disconnect()
      this.events.emit('loginStatus', 403)
    }
  }

  async checkPayloadExpiration (payload) {
    return await this.checkExpiration(this.getTokenFromPayload(payload))
  }

  async checkExpiration (token) {
    try {
      const { data } = await this.http.get(this.formatURL('/checkToken'), {
        headers: {
          'Authorization': token
        }
      })
      if (_.get(data, 'tokenValid', false) === true) {
        console.warn('Token is still valid.')
        return true
      }
      throw new Error('expired token')
    } catch (error) {
      const statusCode = _.get(error, 'response.status', 0)
      console.error('Failed to check expiration:', { error, statusCode })
      if (statusCode >= 400 && statusCode < 500) {
        throw error
      } else {
        return false
      }
    }
  }

  getTokenFromPayload (payload) {
    try {
      const token = _.get(payload, 'credential', false)
      if (!token) {
        throw new Error('no token found')
      }
      return token
    } catch (error) {
      throw error
    }
  }

  joinQuery (query = false) {
    if (!query) {
      return ''
    }
    return `?${_.join(_.map(query, (val, key) => `${key}=${val}`), '&')}`
  }

  formatURL (url, query = false) {
    return `${this.endpoint}${url}${query ? this.joinQuery(query) : ''}`
  }

  async fetchDataForQuery (query = false, signal = undefined) {
    try {
      this.events.emit('has-error', false)
      console.warn('>> fetchDataForQuery:', this.formatURL('/all', query))
      const { data } = await this.http.get(this.formatURL('/all', query), {
        signal: signal
      })
      return data
    } catch (error) {
      this.events.emit('has-error', true)
      const statusCode = _.get(error, 'response.status', 0)
      console.error('Failed to fetch data for query:', { query, error, statusCode })
      if (statusCode >= 400 && statusCode < 500) {
        console.error('loginStatus', statusCode)
        console.error('loginStatus: will autologout and ask to login again')
        this.expireUser()
      }
      throw error
    }
  }
}

export default new GanttPro()
