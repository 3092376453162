<template>
  <div>
    <loading :active.sync="isLoading"
             :is-full-page="true"
    >
      <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" :width="64" :height="64" stroke="#000">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </loading>
    <h1>
      <span>📋 Resources - </span>
      <select @change="onChangeSelect($event, 'filter')">
        <option
          v-for="(option, index) in filters"
          :key="index"
          :value="option.id"
          :selected="option.id === $attrs['filter']"
        >
          {{ option.name }}
        </option>
      </select>
      <template v-if="$attrs['filter'] == 'project'">
        <span> - </span>
        <select @change="onChangeSelect($event, 'project')">
          <option
            v-for="(option, index) in projectsFilter"
            :key="index"
            :value="option.id"
            :selected="option.id === $attrs['project']"
          >
            {{ option.name }}
          </option>
        </select>
        <span> - </span>
        <select @change="onChangeSelect($event, 'department')">
          <option
            v-for="(option, index) in departments"
            :key="index"
            :value="option.id"
            :selected="option.id === $attrs['department']"
          >
            {{ option.name }}
          </option>
        </select>
        <template>
          <div class="refresh-time" :class="{error: hasError}">
            <span v-if="lastRefresh && !hasError">
              Last refresh on {{ lastRefresh }}
            </span>
            <span v-else>
              <inline-loading :class="{error: hasError}" />
              Refreshing...
            </span>
          </div>
        </template>
      </template>
      <template v-else-if="$attrs['filter'] == 'team'">
        <span> - </span>
        <select @change="onChangeSelect($event, 'department')">
          <option
            v-for="(option, index) in departments"
            :key="index"
            :value="option.id"
            :selected="option.id === $attrs['department']"
          >
            {{ option.name }}
          </option>
        </select>
        <template v-if="!isTeamOverview && teams && teams.length > 2">
          <span> - </span>
          <select @change="onChangeSelect($event, 'team')">
            <option
              v-for="(option, index) in teams"
              :key="index"
              :value="option.id"
              :selected="option.id === $attrs['team']"
            >
              {{ option.name }}
            </option>
          </select>
        </template>
        <template v-if="!isTeamOverview">
          <span> - </span>
          <select @change="onChangeSelect($event, 'mode')">
            <option
              v-for="(option, index) in modes"
              :key="index"
              :value="option.id"
              :selected="option.id === $attrs['mode']"
            >
              {{ option.name }}
            </option>
          </select>
        </template>
        <template>
          <div class="refresh-time" :class="{error: hasError}">
            <span v-if="lastRefresh && !hasError">
              Last refresh on {{ lastRefresh }}
            </span>
            <span v-else>
              <inline-loading :class="{error: hasError}" />
              Refreshing...
            </span>
          </div>
        </template>
      </template>
      <template v-else-if="$attrs['filter'] == 'resource'">
        <template>
          <span> - </span>
          <select @change="onChangeSelect($event, 'employee')">
            <option
              v-for="(option, index) in employees"
              :key="index"
              :value="option.id"
              :selected="option.id === $attrs['employee']"
            >
              {{ option.name }}
            </option>
          </select>
          <span> - </span>
          <select @change="onChangeSelect($event, 'mode')">
            <option
              v-for="(option, index) in modes"
              :key="index"
              :value="option.id"
              :selected="option.id === $attrs['mode']"
            >
              {{ option.name }}
            </option>
          </select>
        </template>
        <template>
          <div class="refresh-time" :class="{error: hasError}">
            <span v-if="lastRefresh && !hasError">
              Last refresh on {{ lastRefresh }}
            </span>
            <span v-else>
              <inline-loading :class="{error: hasError}" />
              Refreshing...
            </span>
          </div>
        </template>
      </template>
    </h1>
    <schedule
      v-if="$attrs['filter']"
      :is-loading.sync="isLoading"
      :for-projects="$attrs['filter'] === 'project'"
      :tasks="tasks"
      :special-days="specialDays"
      :projects="projects"
      :period="'week'"
      :departments="departments"
      :resources="resources"
      :mode="$attrs['mode'] || 'tasks'"
      :on-resource-event="onResource"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import Schedule from '../components/resources/Schedule.vue'
import inlineLoading from '../components/inline-loading.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'

import GanttPro from '../services/GanttProService'

export default {
  name: 'Resources',
  components: {
    inlineLoading,
    schedule: Schedule,
    loading: Loading
  },
  data () {
    return {
      isLoading: false,
      timerAPI: null,
      filters: [
        {
          id: 'team',
          name: 'Team'
        },
        {
          id: 'resource',
          name: 'Resource'
        },
        {
          id: 'project',
          name: 'Project'
        }
      ],
      modes: [
        {
          id: 'tasks',
          name: 'Per Tasks'
        },
        {
          id: 'overview',
          name: 'Per Project'
        }
      ],
      hasError: false,
      refreshFrontTimer: false,
      controller: new AbortController(),
      lastRefresh: false,
      resources: [],
      employees: [],
      projects: [],
      departments: [],
      specialDays: [],
      tasks: [],
      displayResources: false,
      heightTable: 450
    }
  },
  computed: {
    projectsFilter: function () {
      return _.get(this, 'projects', [])
      // return _.filter(_.get(this, 'projects', []), p => p.projectId !== 'out-of-office')
    },
    teams: function () {
      const teams = [
        { id: 'all', name: 'All', resources: [] },
        ..._.map(_.get(_.find(this.departments, t => t.id === this.$attrs['department']), 'teams', []), t => {
          return {
            id: _.kebabCase(t.name),
            name: t.name,
            resources: t.resources
          }
        })
      ]
      return teams
    },
    selectDepartments: function () {
      return this.departments
    },
    isTeamOverview: function () {
      return _.get(_.find(this.departments, t => t.id === this.$attrs['department']), 'isOverview', false)
    }
  },
  watch: {
    '$route' () {
      if (this.checkQuery()) {
        return
      }
      clearTimeout(this.refreshFrontTimer)
      this.isLoading = true
      this.refreshFrontTimer = setTimeout(() => {
        this.refreshFront()
      }, 150)
    }
  },
  async mounted () {
    this.refreshAPI()
    GanttPro.webSocket.events.on('update', (resource) => {
      console.debug('change detected; reloading all')
      this.refreshAPI(false, true)
    })
    GanttPro.events.on('has-error', (status) => this.$nextTick(() => this.hasError = status))
    GanttPro.webSocket.events.on('has-error', (status) => this.$nextTick(() => this.hasError = status))
    GanttPro.webSocket.events.on('delta-update', (projectId) => {
      console.warn('delta-update of', projectId, this.$attrs['project'])
      if (this.$attrs['filter'] === 'resource' || this.$attrs['filter'] === 'team') {
        console.debug('change detected; reloading all')
        this.refreshAPI(false, true)
      } else if (this.$attrs['project'] === projectId) {
        console.debug('change detected; reloading only partially')
        this.refreshAPI(true, true)
      }
    })
  },
  beforeDestroy () {
    clearTimeout(this.refreshFrontTimer)
    GanttPro.webSocket.events.off('update')
    GanttPro.webSocket.events.off('delta-update')
  },
  methods: {
    checkQuery () {
      let filter = this.$queryService.getQuery('filter', 'team')
      let isUpdated = false
      let query = {}
      if (filter === 'team') {
        let department = this.$queryService.getQuery('department')
        let team = this.$queryService.getQuery('team')
        let mode = this.$queryService.getQuery('mode')
        if (_.get(mode, 'length', 0) <= 0) {
          mode = 'tasks'
          isUpdated = true
        }
        if (_.get(department, 'length', 0) <= 0) {
          department = _.get(this.departments, '[0].id')
          isUpdated = true
        }
        const currentDeparment = _.find(this.departments, t => t.id === department)
        if (_.get(currentDeparment, 'isOverview', true)) {
          if (_.get(team, 'length', 0) > 0) {
            team = undefined
            isUpdated = true
          }
          if (mode !== 'overview') {
            mode = 'overview'
            isUpdated = true
          }
        } else if (_.get(currentDeparment, 'isExternal', true)) {
          if (_.get(team, 'length', 0) > 0) {
            team = undefined
            isUpdated = true
          }
        } else {
          if (_.get(team, 'length', 0) <= 0) {
            team = _.get(this.teams, '[0].id')
            isUpdated = true
          }
        }
        query = { filter, department, team, mode }
      } else if (filter === 'resource') {
        let employee = this.$queryService.getQuery('employee')
        let mode = this.$queryService.getQuery('mode')
        if (_.get(mode, 'length', 0) <= 0) {
          mode = 'tasks'
          isUpdated = true
        }
        if (_.get(employee, 'length', 0) <= 0) {
          employee = _.get(this.employees, '[0].id')
          isUpdated = true
        }
        query = { filter, employee, mode }
      } else if (filter === 'project') {
        let project = this.$queryService.getQuery('project')
        let department = this.$queryService.getQuery('department')
        if (_.get(project, 'length', 0) <= 0) {
          project = _.get(this.projects, '[0].id')
          isUpdated = true
        }
        if (_.get(department, 'length', 0) <= 0) {
          department = _.get(this.departments, '[0].id')
          isUpdated = true
        }
        query = { filter, project, department }
      }
      let start = this.$queryService.getQuery('start')
      let end = this.$queryService.getQuery('end')
      if (_.get(start, 'length', 0) <= 0 || _.get(end, 'length', 0) <= 0) {
        start = dayjs().isoWeekday(1).format('YYYY-MM-DD')
        end = dayjs().isoWeekday(6).add(8, 'day').format('YYYY-MM-DD')
        isUpdated = true
      }
      query.start = start
      query.end = end
      if (isUpdated) {
        this.$queryService.updateQueryForKeys(query)
        return true
      }
      return false
    },
    onChangeSelect ($event, key, defaultValue) {
      const value = _.get($event, 'target.value', defaultValue)
      this.$queryService.updateQueryForKey(key, value)
    },
    isUsingFilter (filterKey) {
      return this.$attrs['filter'] === filterKey
    },
    refreshAPI (onlyQuery = false, inlineLoading = false) {
      this.$nextTick(async () => {
        if (this.lastRefresh === false) {
          if (!inlineLoading) {
            this.isLoading = true
          }
          this.displayResources = false
        }
        this.lastRefresh = false
        if (!onlyQuery) {
          try {
            console.info(`refreshAPI`)
            await GanttPro.fetchData()
          } catch (error) {
            console.warn('Failed to fetchData:', error)
            this.isLoading = false
            return
          }
        }
        this.departments = []
        this.projects = []
        this.employees = []
        this.specialDays = []
        this.$nextTick(async () => {
          this.departments = _.get(GanttPro, 'departments', [])
          this.projects = _.get(GanttPro, 'projects', [])
          this.employees = _.get(GanttPro, 'employees', [])
          this.specialDays = _.get(GanttPro, 'specialDays', [])
          if (this.checkQuery()) {
            return
          }
          await this.refreshFront(inlineLoading)
          this.$forceUpdate()
          this.$nextTick(() => {
            this.displayResources = true
            this.$forceUpdate()
          })
        })
      })
    },
    onResource (owner) {
      let departmentId = false
      if (!_.isEmpty(owner.departmentId)) {
        departmentId = owner.departmentId
      } else if (_.get(owner, 'isDepartment', false)) {
        departmentId = _.get(owner, 'id', false)
      }
      if (!departmentId || _.isUndefined(_.find(this.departments, t => t.id === departmentId))) {
        return
      }
      this.$queryService.updateQueryForKey('department', departmentId)
    },
    async refreshFront (inlineLoading) {
      if (!inlineLoading) {
        this.isLoading = true
      }
      this.lastRefresh = false
      try {
        this.controller.abort()
        const {resources, tasks} = await GanttPro.fetchDataForQuery(this.$queryService.getQuery(), this.controller.signal)
        this.controller = new AbortController()
        this.resources = []
        this.tasks = []
        this.$nextTick(() => {
          this.resources = resources
          this.tasks = _.sortBy(tasks, ['start', 'end'])
          this.lastRefresh = dayjs().format('HH:mm:ss')
          this.$forceUpdate()
          console.debug(`refreshFront`)
        })
      } catch (error) {
        console.error('Failed to fetchDataForQuery:', error)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .refresh-time {
      margin-left: auto;
      font-size: 12px;
      color: gray;
      margin-right: 0;
      font-weight: normal;
      &.error {
        color: rgb(194, 99, 99);
      }
    }
  }
  .content {
    max-width: 100%;
    margin: 0 24px;
  }
</style>
