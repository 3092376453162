<template>
  <span class="inline-loading">
    O
    <div class="lds-ripple">
      <div />
      <div />
    </div>
  </span>
</template>

<script>
export default {
  setup () {},
  components: {
  },
  props: {
  }
}
</script>
<style lang="scss" scoped>
.inline-loading {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 14px;
  line-height: 100%;
  color: transparent;
  &.error {
    .lds-ripple {
      div {
        border: 4px solid rgb(194, 99, 99);
      }
    }
  }
}
.lds-ripple {
  display: inline-block;
  position: absolute;
  top: -100%;
  left: 0;
  width: 40px;
  height: 40px;
  div {
    position: absolute;
    border: 4px solid rgb(97, 97, 97);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
}
@keyframes lds-ripple {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}

</style>
