<template>
  <div>
    <loading :active.sync="isLoading"
             :is-full-page="true"
    >
      <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" :width="64" :height="64" stroke="#000">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </loading>
    <h1>
      <span>📅 Calendar - </span>
      <select v-model="selectedDepartment">
        <option
          v-for="(options, index) in departments"
          :key="index"
          :value="options.id"
        >
          {{ options.name }}
        </option>
      </select>
      <span> - </span>
      <select v-model="selectedEmployee">
        <option
          v-for="(options, index) in filteredEmployees"
          :key="index"
          :value="options.id"
        >
          {{ options.name }}
        </option>
      </select>
      <template>
        <div class="refresh-time">
          <span v-if="lastRefresh">
            Last refresh on {{ lastRefresh }}
          </span>
          <span v-else>
            Refreshing...
          </span>
        </div>
      </template>
    </h1>
    <div class="header">
      <span class="date">{{ dateRange }}</span>
      <select v-model="selectedView">
        <option
          v-for="(options, index) in viewModeOptions"
          :key="index"
          :value="options.value"
        >
          {{ options.title }}
        </option>
      </select>
      <span @click="onClickNavigation($event)">
        <button
          type="button"
          class="btn btn-default btn-sm move-today"
          data-action="move-today"
          :class="{'has-today': hasToday}"
        >
          Today
        </button>
        <button
          type="button"
          class="btn btn-default btn-sm move-day"
          data-action="move-prev"
        >
          Prev
        </button>
        <button
          type="button"
          class="btn btn-default btn-sm move-day"
          data-action="move-next"
        >
          Next
        </button>
      </span>
    </div>
    <calendar
      ref="tuiCal"
      style="height: 800px"
      :use-detail-popup="useDetailPopup"
      :view="selectedView"
      :calendars="calendars"
      :schedules="schedules"
      :theme="theme"
      :task-view="taskView"
      :schedule-view="scheduleView"
      :month="month"
      :week="week"
      :timezones="timezones"
      :disable-dbl-click="disableDblClick"
      :is-read-only="isReadOnly"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'

import 'vue-loading-overlay/dist/vue-loading.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'
import 'tui-calendar/dist/tui-calendar.css'

import Calendar from '@toast-ui/vue-calendar/src/Calendar.vue'
import Loading from 'vue-loading-overlay'

import myTheme from '../myTheme'
import GanttPro from '../services/GanttProService'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

export default {
  name: 'Calendar',
  components: {
    calendar: Calendar,
    loading: Loading
  },
  data () {
    return {
      isLoading: false,
      timerAPI: null,
      lastRefresh: false,
      selectedView: 'month',
      selectedDepartment: '0',
      selectedEmployee: '0',
      viewModeOptions: [
        {
          title: 'Monthly',
          value: 'month'
        },
        {
          title: 'Weekly',
          value: 'week'
        },
        {
          title: 'Daily',
          value: 'day'
        }
      ],
      dateRange: '',
      calendars: [],
      schedules: [],
      timezones: [
        {
          timezoneOffset: 420,
          displayLabel: 'GMT+08:00',
          tooltip: 'Shanghai'
        }
      ],
      theme: myTheme,
      month: {
        narrowWeekend: true,
        startDayOfWeek: 1
      },
      week: {
        startDayOfWeek: 1,
        narrowWeekend: true,
        showTimezoneCollapseButton: true,
        timezonesCollapsed: true,
        hourStart: 0,
        hourEnd: 0
      },
      hasToday: false,
      taskView: ['task'],
      scheduleView: ['allday'],
      useDetailPopup: true,
      disableDblClick: true,
      isReadOnly: true,
      departments: [
        {
          name: 'Loading...',
          id: 0
        }
      ],
      filteredEmployees: [
        {
          name: 'Loading...',
          id: 0
        }
      ]
    }
  },
  watch: {
    selectedView (newValue) {
      this.$refs.tuiCal.invoke('changeView', newValue, true)
      this.setRenderRangeText()
    },
    async selectedDepartment (newValue) {
      const departmentId = this.getQuery('department')
      if (departmentId !== newValue) {
        const query = _.clone(this.$route.query)
        query.department = newValue
        this.updateQuery(departmentId, query)
        this.selectedDepartment = newValue
        await this.refreshFront()
      }
    },
    async selectedEmployee (newValue) {
      const employeeId = this.getQuery('employee')
      if (newValue && employeeId !== newValue) {
        const query = _.clone(this.$route.query)
        query.employee = _.kebabCase(newValue)
        this.updateQuery(employeeId, query)
        this.selectedEmployee = newValue
        // console.info(`watch - selectedEmployee `, this.selectedEmployee)
        await this.refreshFront()
      }
    },
    '$route' (newRouter) {
      const query = _.clone(this.$route.query)
      const departmentId = _.get(newRouter, 'query.department', false)
      if (!departmentId) {
        this.selectedDepartment = _.get(this.departments, '[0].id', '0')
        query.department = this.selectedDepartment
        this.$router.replace({ query })
        this.refreshFront()
        return
      }

      if (departmentId !== this.selectedDepartment) {
        this.selectedDepartment = departmentId
        this.refreshFront()
      }
    }
  },
  async mounted () {
    this.setRenderRangeText()
    this.refreshAPI()
    GanttPro.webSocket.events.on('update', (resource) => this.refreshAPI())
    GanttPro.webSocket.events.on('delta-update', (projectId) => this.refreshAPI())
  },
  beforeDestroy () {
    GanttPro.webSocket.events.off('update')
    GanttPro.webSocket.events.off('delta-update')
  },
  methods: {
    updateQuery (value, query) {
      if (value) {
        this.$router.push({ query })
      } else {
        this.$router.replace({ query })
      }
    },
    refreshAPI () {
      this.$nextTick(async () => {
        this.isLoading = true
        if (this.lastRefresh === false) {
          this.isLoading = true
        }
        this.lastRefresh = false
        try {
          await GanttPro.fetchData()
        } catch (error) {
          console.warn('Failed to fetchData:', error)
          this.isLoading = false
          return
        }
        this.departments = []
        this.departments = GanttPro.departments
        this.filteredEmployees = []
        this.selectedDepartment = this.getQuery('department') || _.get(this.departments, '[0].id', '0')
        this.filteredEmployees = GanttPro.filteredEmployees
        this.selectedEmployee = this.getQuery('employee') || _.get(this.filteredEmployees, '[0].id', '0')
        await this.refreshFront()
        this.isLoading = false
        this.lastRefresh = dayjs().format('HH:mm:ss')
        this.$forceUpdate()
      })
    },
    setRenderRangeText () {
      const { invoke } = this.$refs.tuiCal
      const view = invoke('getViewName')
      const calDate = dayjs(invoke('getDate'))
      const rangeStart = dayjs(invoke('getDateRangeStart'))
      const rangeEnd = dayjs(invoke('getDateRangeEnd'))
      let dateRangeText = ''
      if (view === 'month') {
        dateRangeText = `Week ${rangeStart.week()} - ${rangeEnd.week()}, ${calDate.format('YYYY-MM')}`
      } else if (view === 'week') {
        dateRangeText = `Week ${rangeStart.week()} - ${rangeStart.format('YYYY-MM-DD')} ~ ${rangeEnd.format('YYYY-MM-DD')}`
      } else {
        dateRangeText = `Week ${calDate.week()} - ${calDate.format('YYYY-MM-DD')}`
      }

      this.dateRange = dateRangeText
      this.hasToday = dayjs() >= rangeStart && dayjs() <= rangeEnd
    },
    onClickNavigation (event) {
      if (event.target.tagName === 'BUTTON') {
        const { target } = event
        let action = target.dataset
          ? target.dataset.action
          : target.getAttribute('data-action')
        action = action.replace('move-', '')

        this.$refs.tuiCal.invoke(action)
        this.setRenderRangeText()
      }
    },
    getQuery (field = false) {
      if (field) {
        return _.get(this.$router, `history.current.query.${field}`, false)
      }
      return _.get(this.$router, `history.current.query`, {})
    },
    async refreshFront () {
      this.isLoading = true
      try {
        const data = await GanttPro.fetchDataForQuery(this.getQuery())
        this.calendars = _.get(data, 'calendars', [])
        this.schedules = _.get(data, 'schedules', [])
        this.filteredEmployees = _.get(data, 'filteredEmployees', [])
        console.info(`refreshed front`)
      } catch (error) {
        console.error(`Error while refreshing front: `, error)
      }
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin: 0 0 10px;
  color: #555;
  .date {
    margin-right: 16px;
    color: #555;
    font-weight: 900;
    font-size: 18px;
  }
}
h1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .refresh-time {
    margin-left: auto;
    font-size: 12px;
    color: gray;
    margin-right: 0;
    font-weight: normal;
  }
}
</style>
