import autoBind from 'auto-bind'
import _ from 'lodash'
import { EventEmitter } from 'tiny-events'

class WebSocketService {
  constructor () {
    autoBind(this)
    this._status = {
      isConnecting: false,
      isConnected: false
    }
    this.events = new EventEmitter()
  }

  disconnect () {
    try {
      if (_.get(this.client, 'onopen', false) !== false) {
        this.client.onopen = null
      }
      if (_.get(this.client, 'onclose', false) !== false) {
        this.client.onclose = null
      }
      if (_.get(this.client, 'close', false) !== false && _.get(this.client, 'readyState', 3) === 1) {
        this.client.close()
      }
    } catch (error) {
      console.debug('Websocket::disconnect error on close:', error)
    }
    this._status.isConnecting = false
    this._status.isConnected = false
    clearTimeout(this.heatbeat)
    clearTimeout(this.connecting)
    console.debug('Websocket::disconnected.')
  }

  reconnect () {
    this.events.emit('has-error', true)
    this.disconnect()
    console.debug('Websocket::disconnect: Reconnecting in 5s...')
    this.connecting = setTimeout(() => this.connect(), 5000)
  }

  connect () {
    if (this._status.isConnecting) {
      console.debug('Websocket::connect: already connecting')
    }
    this.disconnect()
    this._status.isConnecting = true
    let protocol = 'ws'
    if (window.location.protocol === 'https:') {
      protocol = 'wss'
    }
    clearTimeout(this.connecting)
    this.connecting = setTimeout(() => {
      if (_.get(this.client, 'close', false) !== false && _.get(this.client, 'readyState', 3) === 0) {
        this.reconnect()
      }
    }, 10000)
    const server = `${protocol}://${window.location.hostname}:${window.location.port}/api`
    console.debug('Websocket::connect: Connecting to server:', server)
    this.client = new WebSocket(server)
    this.client.onopen = () => {
      console.debug('Websocket::onOpen: Connected')
      clearTimeout(this.connecting)
      this._status.isConnecting = false
      this._status.isConnected = true
      this.events.emit('onConnect', this)
      this.events.emit('has-error', false)
      this.onHeartbeat()
    }
    this.client.onclose = this.reconnect
    this.client.onmessage = this.onMessage
  }

  onMessage (buffer) {
    try {
      const data = JSON.parse(_.get(buffer, 'data', ''))
      if (_.get(data, 'action', false) !== false) {
        if (data.action === 'ping') {
          this.pong()
          return
        }
        console.debug('Received:', data)
        if (_.get(data, 'action', false) !== false) {
          this.events.emit(_.get(data, 'action', false), _.get(data, 'data', {}))
        }
      }
    } catch (error) {
      console.error('WebSocket::onMessage: Error:', error)
    }
  }

  authenticate (token) {
    console.debug('WebSocket::authenticate')
    this.send({action: 'authenticate', token: token})
  }

  send (data) {
    try {
      this.client.send(JSON.stringify(data))
    } catch (error) {
      console.error('WebSocket::send: Error:', error)
    }
  }

  pong () {
    console.debug('WebSocket::pong: Responded')
    this.send({action: 'pong', bearer: '0123456789'})
    this.onHeartbeat()
  }

  onHeartbeat () {
    clearTimeout(this.heatbeat)
    this.heatbeat = setTimeout(() => {
      console.debug('WebSocket::onHeartbeat: Timeout')
      this.client.close()
    }, 30000 + 5000)
  }
}

export default WebSocketService
