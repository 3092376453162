<template>
  <div class="vue-scheduler">
    <div ref="table-headers" class="timeline fixed" :style="{ width: `${fixedHeadersWidth}px` }" :class="{displayed: displayFixedHeaders}">
      <div ref="resourceSlot" class="resource-slot" :style="{ 'flex-basis': `${resourceSlotWidth + 1}px` }">
        <b>Resources</b>
      </div>
      <div v-for="slot in slots" :key="slot.id" class="timeline-slot" :style="{ width: `${timelineSlotWidthPercentage}%` }" :class="{'is-today': slot.isToday, 'is-saturday': slot.isSaturday, 'is-sunday': slot.isSunday}">
        <div class="cell" :style="{ height: `${headerSize}px` }">
          <div v-if="slots.length < 28" class="day">{{ slot.day }}</div>
          <div class="date">{{ slot.date }}</div>
        </div>
      </div>
    </div>
    <h3 class="header" style="text-align: left;">
      <span class="date">{{ getSelectedTimeslot() }}</span>
      <select v-model="selectedGranularity">
        <option
          v-for="(options, index) in granularity"
          :key="index"
          :value="options.id"
        >
          {{ options.name }}
        </option>
      </select>
      <button type="button" class="btn btn-default btn-sm" :class="{'has-today': hasToday}" @click="onToday">Today</button>
      <button type="button" class="btn btn-default btn-sm" @click="onPrevious">Previous</button>
      <button type="button" class="btn btn-default btn-sm" @click="onNext">Next</button>
    </h3>
    <div id="scheduler-container" class="scheduler-container" :style="{ height: `${timelineHeight}px` }">
      <div ref="table-headers" class="timeline" :style="{ height: `${timelineHeight}px` }">
        <div ref="resourceSlot" class="resource-slot" :style="{ 'flex-basis': `${resourceSlotWidth}px` }">
          <b>Resources</b>
        </div>
        <div v-for="slot in slots"
             :key="slot.id"
             class="timeline-slot"
             :style="{
               width: `${timelineSlotWidthPercentage}%`
             }"
             :class="{
               'is-today': slot.isToday,
               'is-saturday': slot.isSaturday && isSpecialDay(slot) !== 'working-day',
               'is-sunday': slot.isSunday && isSpecialDay(slot) !== 'working-day',
               'is-national-holiday': isSpecialDay(slot) === 'national-holiday'
             }"
        >
          <div class="cell" :style="{ height: `${headerSize}px` }">
            <div v-if="slots.length < 28" class="day">{{ slot.day }}</div>
            <div class="date">{{ slot.date }}</div>
          </div>
        </div>
      </div>
      <div class="resources" :style="{top: `${offsetGrid}px`}">
        <template v-if="resourceLines.length > 0">
          <div v-for="(resource, i) in resourceLines" :key="i" class="resource-row" :style="{ height: `${resource.height}px`, 'min-height': `${resourceMinHeight}px` }">
            <div class="resource-row-identity" :style="{ width: `${resourceSlotWidth}px`, 'min-width': `${resourceSlotWidth}px`,'padding': `${linePadding}px` }" :class="{'is-overview': isOverview(resource)}" @click="onResource(resource.owner)">
              <img v-if="resource && resource.owner && resource.owner.photo" :src="resource.owner.photo" :style="{ background: '#0000002e', width: `${lineHeight - linePadding * 2}px`, height: `${lineHeight - linePadding * 2}px`, 'border-radius': `${lineHeight}px` }">
              <div class="identity">
                <div class="resource-name">{{ `${resource.owner.name}` }}</div>
                <div v-if="resource.owner.role" class="resource-role">{{ `${resource.owner.role}` }}</div>
              </div>
            </div>
            <div class="tasks">
              <div v-if="mode === 'tasks'" class="task-row workload" :style="{ height: `10px`}">
                <div v-for="slot in slots" :key="slot.id" class="workload-slot"
                     :style="{ width: `${timelineSlotWidthPercentage}%` }"
                     :class="{'overload': getTotalWorkloadForDay(resource.tasksLines, slot) >= 100}"
                >
                  {{ getTotalWorkloadForDay(resource.tasksLines, slot) }}%
                </div>
              </div>
              <div v-for="(taskRow, taskRowIndex) in resource.tasksLines"
                   :key="taskRowIndex" class="task-row"
                   :style="{
                     height: `${lineHeight}px`
                   }"
              >
                <template v-if="!taskRow.isEmpty">
                  <div v-for="task in taskRow"
                       :id="`task-container-${taskRowIndex}-${task.id}`"
                       :key="task.id"
                       class="task"
                       :style="{
                         '--leftPosition': `${task.position}%`,
                         '--widthSize': `${task.width}%`,
                         '--paddingSize': `${linePadding}px`,
                         '--heightSize': `${lineHeight}px`,
                         '--isVisible': `${task.hidden ? 0 : 1}`
                       }"
                       :data-start="task.start"
                       :data-end="task.end"
                       @click.stop.prevent="togglePanel(task)"
                  >
                    <div :id="`task-cell-${taskRowIndex}-${task.id}`"
                         class="cell"
                         :style="{
                           '--degree': `${task.fromHiBob ? '-55deg' : '45deg'}`,
                           '--colorDarkStroke': `${shadeColor(task.color, -60)}`,
                           '--colorBrightStroke': `${shadeColor(task.color, 40)}`,
                           '--primary-color': `${task.color}`,
                           '--secondary-color': `${shadeColor(task.color, 40)}`,
                           '--backgroundColor': task.color
                         }"
                         :class="{
                           'is-pending': task.status === 'pending',
                           'is-bright': !task.darkMode,
                           'is-dark': task.darkMode,
                           'is-selected': `${mode == 'overview' ? task.project.projectId : task.id}` == `${selectedPanelData}`,
                           'is-stroke': task.priority <= 1 || task.fromHiBob
                         }"
                    >
                      <div :title="unescape(task.title + (task.status === 'pending' ? ' - Pending Approval' : ''))" class="title-wrapper">
                        <div v-if="task.priorityPercentage" class="priority">
                          <div v-for="slot in task.numberOfSlots"
                               :id="`priority-slot-${slot}`"
                               :key="slot"
                               class="priority-slot"
                               :class="{
                                 'only-one': task.numberOfSlots === 1
                               }"
                               :style="{
                                 '--backgroundColor': shadeColor(task.color, 40),
                                 '--widthSize': `${100 / task.numberOfSlots}%`
                               }"
                          >
                            {{ task.priorityPercentage }}%
                          </div>
                        </div>
                        <div class="title">
                          <div class="title-content"
                               :title="unescape(task.title + (task.status === 'pending' ? ' - Pending Approval' : ''))"
                               v-html="task.title + (task.status === 'pending' ? ' - Pending Approval' : '')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="task empty" />
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <panel ref="panel" class="panel" :class="{'is-empty': !panelData,'has-no-description': !panelData || !panelData.descriptionsTasks || !panelData.descriptionsTasks.length || panelData.descriptionsTasks.length === 0}">
      <div v-if="panelData" class="panel-detail">
        <div ref="panelContainer" class="panel-container">
          <div ref="panelSectionHeader" class="panel-header">
            <div class="panel-title">
              <a :href="generateProjectUrl(panelData)" target="_blank">
                <span>
                  {{ panelData.title }}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                       stroke-linejoin="round" class="feather feather-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <polyline points="15 3 21 3 21 9" />
                    <line x1="10" y1="14" x2="21" y2="3" />
                  </svg>
                </span>
              </a>
            </div>
            <div class="panel-calendar-content">From {{ formatDate(panelData.start) }} to {{ formatDate(panelData.end) }}</div>
          </div>
          <vue-custom-scrollbar ref="panelScrollbar" class="panel-section-detail">
            <div class="panel-detail-items">
              <template v-if="panelData.descriptionsTasks.length">
                <div v-for="(task, i) in panelData.descriptionsTasks" :key="i" class="panel-detail-item">
                  <div class="panel-detail-item-title">
                    <a :href="generateTaskUrl(panelData.project.projectId, task.id)" target="_blank">
                      <span>
                        {{ task.fullName }}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-external-link"
                        >
                          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                          <polyline points="15 3 21 3 21 9" />
                          <line x1="10" y1="14" x2="21" y2="3" />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div class="panel-detail-item-description" v-html="task.description" />
                </div>
              </template>
              <template v-else>
                <div class="panel-detail-item-no-items">
                  💡 No description found, don't hesitate to add tasks description in GanttPro for better collaboration.
                </div>
              </template>
            </div>
          </vue-custom-scrollbar>
          <div class="panel-top-line" style="background-color: #5ce1c2" />
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

dayjs.extend(isBetween)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

export default {
  setup () {},
  components: {
    panel: () => import('./Panel.vue'),
    vueCustomScrollbar
  },
  props: {
    departments: {
      type: Array,
      default: () => []
    },
    tasks: {
      type: Array,
      default: () => []
    },
    projects: {
      type: Array,
      default: () => []
    },
    specialDays: {
      type: Array,
      default: () => []
    },
    forProjects: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'overview'
    },
    resources: {
      type: Array,
      default: () => []
    },
    selected: String,
    onResourceEvent: Function
  },
  data () {
    return {
      unescape: _.unescape,
      hasToday: false,
      panelData: false,
      slotsCount: 14,
      renderTimer: false,
      lineHeight: 60,
      headerSize: 34,
      offsetGrid: 0,
      headerBottomMargin: 7,
      displayFixedHeaders: false,
      fixedHeadersWidth: 0,
      selectedPanelData: 0,
      resourceMinHeight: 60,
      linePadding: 4,
      timelineSlotWidthPercentage: 100 / 14,
      resourceSlotWidth: 200,
      selectedTimeslot: dayjs(),
      taskGrid: [],
      slots: [],
      selectedGranularity: '2',
      granularity: [
        {
          id: '1',
          name: 'One Week'
        },
        {
          id: '2',
          name: 'Two Weeks'
        },
        {
          id: '3',
          name: 'Three Weeks'
        },
        {
          id: '4',
          name: 'Four Weeks'
        }
      ]
    }
  },
  computed: {
    resourceLines: function () {
      let resources = []
      for (const [key, resource] of Object.entries(this.taskGrid)) {
        const foundResource = _.find(this.resources, r => _.get(r, 'isDepartment', false) ? r.id === key && !r.isOverview : _.includes(r.ids, key) && !r.isOverview)
        if (foundResource) {
          resource.owner = foundResource
          resource.value = key
          resource.height = _.get(resource, 'lines.length', 0) * this.lineHeight
          if (this.mode === 'tasks') {
            resource.height = resource.height + 10
          }
          // TODO: hugo
          resources.push(resource)
        }
      }
      let indexResources = _.flatten(_.map(this.departments, 'members'))

      if (_.filter(resources, r => _.get(r, 'owner.isGrouped', false)).length > 0) {
        indexResources = _.flatten(_.map(this.departments, 'name'))
      }
      resources = _.sortBy(resources, r => {
        let position = _.indexOf(indexResources, _.get(r, 'owner.name', 'unknown'))
        if (position === -1) {
          position = indexResources.length
        }
        return position
      })

      if (_.get(_.first(resources), 'owner.isDepartment', false)) {
        resources = _.compact(_.map(resources, (resource) => {
          const tasksList = []
          resource.lines = _.compact(_.map(resource.lines, (line) => {
            line.tasks = _.compact(_.map(line.tasks, (task) => {
              if (tasksList.indexOf(task.id) === -1) {
                tasksList.push(task.id)
                return task
              } else {
                // console.info(`task ${task.title} already listed in ${resource.value} deparment`)
                return false
              }
            }))
            if (_.get(line, 'tasks.length', 0) === 0) {
              return false
            }
            return line
          }))
          resource.height = _.get(resource, 'lines.length', 0) * this.lineHeight
          return resource
        }))
      }
      const tasksLines = this.getTasksLines(resources)
      _.each(resources, (resource) => {
        resource.tasksLines = _.filter(tasksLines, (line) => {
          let foundOwner = false
          _.each(line, (task) => {
            if (_.get(task, 'ownerId', false) === resource.value) {
              foundOwner = true
              return false
            }
          })
          return foundOwner
        })
      })
      // console.warn(`resources = `, resources)
      return resources
    },
    tasksLines: function () {
      let lines = []
      let indexResources = _.flatten(_.map(this.departments, 'members'))
      if (_.filter(this.taskGrid, r => _.get(r, 'owner.isGrouped', false)).length > 0) {
        indexResources = _.flatten(_.map(this.departments, 'name'))
      }
      let taskGrid = _.sortBy(this.taskGrid, tasks => {
        let position = _.indexOf(indexResources, _.get(tasks, 'owner.name', 'unknown'))
        if (position === -1) {
          position = indexResources.length
        }
        return position
      })
      if (this.forProjects) {
        taskGrid = _.filter(taskGrid, (t) => _.get(t, 'owner.name', false))
      }
      for (const resource of taskGrid) {
        const tasks = _.get(resource, 'lines', []).map((line) => line.tasks)
        if (!this.forProjects && !_.get(_.first(this.resourceLines), 'owner.isDepartment', false) && tasks.length === 0) {
          tasks.push({isEmpty: true})
        } else if (this.forProjects && tasks.length === 0) {
          tasks.push({isEmpty: true})
        }
        lines.push(...tasks)
      }
      // console.warn('tasksLines =', lines)
      return lines
    },
    timelineHeight: function () {
      return this.tasksLines.length * this.lineHeight + this.offsetGrid + (this.mode === 'tasks' ? this.resourceLines.length * 10 : 0)
    }
  },
  watch: {
    resources: { handler: 'render' },
    tasks: { handler: 'render' },
    selectedTimeslot: { handler: 'updateTimeslot' },
    selectedGranularity: { handler: 'onGranularityChange' }
  },
  mounted () {
    this.updateTimeslot()
    this.render()
    document.addEventListener('click', this.hidePanel)
    document.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    this.handleScroll()
  },
  beforeDestroy () {
    document.removeEventListener('click', this.hidePanel)
    document.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  },
  methods: {
    isSpecialDay (slot) {
      const day = slot.value.format('YYYY-MM-DD')
      return _.get(_.find(this.specialDays, workingDay => workingDay.date === day), 'type', false)
    },
    getTotalWorkloadForDay (tasksLines, slot) {
      let totalWorkload = 0
      _.each(tasksLines, (taskLine) => {
        _.each(taskLine, (task) => {
          if (slot.value.isBetween(task.start, task.end, 'day', '[]')) {
            totalWorkload += _.toNumber(task.priorityPercentage)
          }
        })
      })
      return _.isNaN(totalWorkload) ? 0 : totalWorkload
    },
    getTasksLines (resourcesLines) {
      let lines = []
      let indexResources = _.flatten(_.map(this.departments, 'members'))

      if (_.filter(this.taskGrid, r => _.get(r, 'owner.isGrouped', false)).length > 0) {
        indexResources = _.flatten(_.map(this.departments, 'name'))
      }
      let taskGrid = _.sortBy(this.taskGrid, tasks => {
        let position = _.indexOf(indexResources, _.get(tasks, 'owner.name', 'unknown'))
        if (position === -1) {
          position = indexResources.length
        }
        return position
      })
      if (this.forProjects) {
        taskGrid = _.filter(taskGrid, (t) => _.get(t, 'owner.name', false))
      }
      for (const resource of taskGrid) {
        const tasks = _.get(resource, 'lines', []).map((line) => line.tasks)
        if (!this.forProjects && !_.get(_.first(resourcesLines), 'owner.isDepartment', false) && tasks.length === 0) {
          tasks.push({isEmpty: true})
        } else if (this.forProjects && tasks.length === 0) {
          tasks.push({isEmpty: true})
        }
        lines.push(...tasks)
      }
      // console.warn('tasksLines =', lines)
      return lines
    },
    shadeColor (colorCode, amount) {
      let usePound = false
      if (colorCode[0] === '#') {
        colorCode = colorCode.slice(1)
        usePound = true
      }
      const num = parseInt(colorCode, 16)
      let r = (num >> 16) + amount
      if (r > 255) {
        r = 255
      } else if (r < 0) {
        r = 0
      }
      let b = ((num >> 8) & 0x00FF) + amount
      if (b > 255) {
        b = 255
      } else if (b < 0) {
        b = 0
      }
      let g = (num & 0x0000FF) + amount
      if (g > 255) {
        g = 255
      } else if (g < 0) {
        g = 0
      }
      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    },
    updateTimeslot () {
      this.$queryService.updateQueryForKeys({start: this.selectedTimeslot.isoWeekday(1).format('YYYY-MM-DD'), end: this.selectedTimeslot.isoWeekday(1).add(this.slotsCount, 'day').format('YYYY-MM-DD')})
    },
    isOverview (resource) {
      return resource && resource.owner && (resource.owner.isGrouped || resource.owner.isDepartment)
    },
    handleScroll () {
      this.displayFixedHeaders = document.documentElement.scrollTop >= 170
      this.fixedHeadersWidth = _.get(this.$refs, `['table-headers'].clientWidth`, 0)
    },
    hidePanel (event) {
      const domPath = _.get(event, 'path', [])
      let found = false
      for (const dom of domPath) {
        const classList = _.get(dom, 'classList', false)
        if (classList && _.isFunction(classList.contains) && classList.contains('panel')) {
          found = true
        }
      }
      if (!found) {
        this.togglePanel(false)
      }
    },
    getTasksDescriptions (data) {
      if (this.mode === 'overview') {
        const projectId = _.get(data, 'project.projectId', false)
        const allTasks = _.get(_.find(this.projects, p => p.projectId === projectId), 'tasks', [])
        let tasks = _.filter(allTasks, t => !_.isEmpty(_.get(t, 'description', '')))
        tasks = _.map(tasks, t => {
          t.fullName = _.map(this.getParents(allTasks, t), t => t.name).join(' / ')
          if (_.isEmpty(t.fullName)) {
            t.fullName = t.name
          } else {
            t.fullName = t.fullName + ` / ${t.name}`
          }
          return t
        })
        return tasks
      } else {
        const projectId = _.get(data, 'project.projectId', false)
        const allTasks = _.get(_.find(this.projects, p => p.projectId === projectId), 'tasks', [])
        const currentTask = _.find(allTasks, t => `${t.id}` === `${data.id}`)
        const parents = this.getParents(allTasks, currentTask)
        parents.push(currentTask)
        let tasks = _.filter(parents, t => !_.isEmpty(_.get(t, 'description', '')))
        tasks = _.map(tasks, t => {
          t.fullName = _.map(this.getParents(allTasks, t), t => t.name).join(' / ')
          if (_.isEmpty(t.fullName)) {
            t.fullName = t.name
          } else {
            t.fullName = t.fullName + ` / ${t.name}`
          }
          return t
        })
        return tasks
      }
    },
    getParents (tasks, task) {
      const parents = []
      let currentParent = this.getParent(tasks, task)
      while (currentParent) {
        parents.push(currentParent)
        currentParent = this.getParent(tasks, currentParent)
      }
      return _.reverse(parents)
    },
    getParent (tasks, task) {
      const parentId = _.get(task, 'parent', '')
      if (_.isEmpty(parentId)) {
        return undefined
      }
      const newParent = _.find(tasks, t => `${t.id}` === `${parentId}` && t.name !== 'Total estimate')
      return newParent
    },
    formatDate (date) {
      return _.isFunction(date.format) ? date.format('YYYY/MM/DD') : date
    },
    async sleep (ms) {
      return new Promise(resolve => setTimeout(() => resolve(), ms))
    },
    async togglePanel (data) {
      const project = _.find(this.projects, project => project.projectId === _.get(data, 'project.projectId', '?'))
      if (`${data.id}`.search('hibob') >= 0 || _.get(project, 'fromHiBob', false) === true) {
        return
      }
      const idPath = this.mode === 'overview' ? 'project.projectId' : 'id'
      if (!data || _.get(data, idPath, '?') === _.get(this.panelData, idPath, '??')) {
        this.$refs.panel.setState('close')
        this.selectedPanelData = 0
        this.panelData = false
        return
      }
      data.descriptionsTasks = this.getTasksDescriptions(data)
      this.selectedPanelData = _.get(data, idPath, 0)
      if (_.get(data, idPath, '?') !== _.get(this.panelData, idPath, '??')) {
        this.$refs.panel.setState('close')
        await this.sleep(300)
        this.$refs.panel.setState('half')
      }
      this.panelData = data
      this.refreshPanelScrollbar()
    },
    refreshPanelScrollbar () {
      this.$nextTick(() => {
        if (_.get(this.$refs, 'panelScrollbar', false)) {
          this.$refs.panelScrollbar.update()
        }
      })
    },
    onResource (owner) {
      if (!_.isUndefined(this.onResourceEvent)) {
        this.onResourceEvent(owner)
      }
    },
    onGranularityChange (newValue) {
      this.slotsCount = 7 * newValue
      this.updateTimeslot()
    },
    calculateSlots () {
      let i
      const data = []
      const firstDayOfWeek = this.selectedTimeslot.isoWeekday(1)
      for (i = 0; i < this.slotsCount; i++) {
        const date = firstDayOfWeek.add(i, 'day')
        data.push({
          id: i,
          date: `${date.format('DD MMM')}`,
          value: date,
          day: date.format('ddd'),
          isToday: dayjs().isSame(date, 'day'),
          isSaturday: date.day() === 6,
          isSunday: date.day() === 0
        })
      }
      return data
    },
    render () {
      console.debug('render call')
      clearTimeout(this.renderTimer)
      this.renderTimer = setTimeout(() => {
        this.$nextTick(() => {
          this.offsetGrid = this.headerSize + this.headerBottomMargin
          this.timelineSlotWidthPercentage = 100 / this.slotsCount
          this.slots = this.calculateSlots()
          this.hasToday = dayjs() >= _.first(this.slots).value && dayjs() <= _.last(this.slots).value
          this.taskGrid = this.computeTasksGrid(this.tasks)
          this.$emit('update:isLoading', false)
          console.debug('render done')
        })
      }, 150)
    },
    getSelectedTimeslot () {
      return `Week ${this.selectedTimeslot.week()}, ${this.selectedTimeslot.isoWeekday(1).format('YYYY-MM-DD')} - ${this.selectedTimeslot.isoWeekday(6).add(8, 'day').format('YYYY-MM-DD')}`
    },
    onToday () {
      this.selectedTimeslot = dayjs()
    },
    onPrevious () {
      this.selectedTimeslot = this.selectedTimeslot.add(-1 * this.selectedGranularity, 'week')
    },
    onNext () {
      this.selectedTimeslot = this.selectedTimeslot.add(1 * this.selectedGranularity, 'week')
    },
    overlaps (task, tasks) {
      const es = dayjs(task.start).startOf('day')
      const ee = dayjs(task.end).endOf('day')
      for (let other of tasks) {
        const os = dayjs(other.start)
        const oe = dayjs(other.end)
        if (!(ee < os || oe < es)) {
          return other
        }
      }
      return false
    },
    accumulate (r, element, a, that) {
      r[element] = r[element] || { lines: [] }
      let task = that.convertTask(a, element)
      for (const line of r[element].lines) {
        if (line.tasks === undefined) {
          line.tasks = [task]
          task = null
        } else if (!that.overlaps(a, line.tasks)) {
          line.tasks.push(task)
          task = null
          break
        }
      }
      if (task) {
        r[element].lines.push({ tasks: [task] })
      }
    },
    filterTasks (tasks) {
      let _tasks = _.cloneDeep(tasks)
      if (tasks === undefined) {
        return []
      }
      if (this.slots.length === 0) {
        return []
      }
      const start = _.get(_.first(this.slots), 'value', false)
      const end = _.get(_.last(this.slots), 'value', false)
      if (!start || !end) {
        return []
      }
      return _.filter(_tasks, t => {
        const resourcesIds = _.map(_.get(t, 'resources', []), r => r.resourceId)
        const taskStart = dayjs(t.start)
        const taskEnd = dayjs(t.end)
        if (taskEnd < start || taskStart > end) {
          return false
        }
        let ids = []
        _.forEach(this.resources, resource => {
          _.forEach(resource.ids, id => {
            if (_.includes(resourcesIds, id)) {
              ids.push(resource.id)
            }
          })
        })
        if (ids.length === 0) {
          return false
        }
        t.owners = _.flattenDeep(ids)
        if (taskStart < start) {
          t.start = start
        }
        if (taskEnd > end) {
          t.end = end
        }
        return true
      })
    },
    computeTasksGrid (tasks) {
      let _tasks = this.filterTasks(tasks)
      const that = this

      const matrix = _tasks.reduce(function (r, a) {
        let ids = _.get(a, 'owners', false)
        _.forEach(ids, id => {
          if (!id) {
            console.log(`⚠️ the item ${a.title} (${a.id}) has no resource id`)
          } else {
            id = [id]
          }
          id.forEach((element) => that.accumulate(r, element, a, that))
        })
        return r
      }, Object.create(null))
      // console.warn(`MATRIX - `, matrix)
      const resourcesToParse = this.forProjects ? _.filter(this.resources, {isOverview: false}) : this.resources
      _.forEach(resourcesToParse, resource => {
        let found = false
        let resourceId = false
        for (const key in matrix) {
          if (resource.id === key) {
            if (!found) {
              found = true
              resourceId = key
            }
          }
          if (found) {
            break
          }
        }
        if (!found) {
          _.set(matrix, `['${resource.id}']`, {
            height: 0,
            lines: [],
            owner: resource,
            value: resource.id
          })
        } else if (resourceId) {
          _.set(matrix, `['${resourceId}'].owner`, resource)
        }
      })
      return matrix
    },
    generateTaskUrl (projectId, taskId) {
      return `https://app.ganttpro.com/#/project/${projectId}/gantt/task/${taskId}`
    },
    generateProjectUrl (panelData) {
      const projectId = _.get(panelData, 'project.projectId', false)
      const taskId = _.get(panelData, 'id', false)
      if (projectId && taskId) {
        if (this.mode === 'tasks') {
          return this.generateTaskUrl(projectId, taskId)
        }
        return `https://app.ganttpro.com/#/project/${projectId}/gantt`
      }
      return `https://app.ganttpro.com`
    },
    convertTask (task, resourceId) {
      const position = this.computePosition(task.start)
      return {
        title: task.title,
        fromHiBob: _.get(task, 'fromHiBob', false),
        position: position * this.timelineSlotWidthPercentage,
        darkMode: task.darkMode,
        start: task.start,
        end: task.end,
        priority: task.priority,
        status: task.status,
        priorityPercentage: task.priorityPercentage,
        color: task.project.color,
        project: task.project,
        width: (this.computePosition(task.end) - position + 1) * this.timelineSlotWidthPercentage,
        numberOfSlots: this.computePosition(task.end) - position + 1,
        hidden: position < 0,
        resources: task.resources,
        ownerId: resourceId,
        id: task.id
      }
    },
    computePosition (date) {
      const start = _.get(_.first(this.slots), 'value', false)
      const end = _.get(_.last(this.slots), 'value', false)
      if (!start || !end) {
        return 0
      }
      const evaluatedDate = dayjs(date)
      const index = _.findIndex(this.slots, s => s.value.isSame(evaluatedDate, 'day'))
      return index
    }
  }
}
</script>

<style lang="scss">
@mixin stroke($color: #000, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}

.header {
  margin: 0 0 10px;
  color: #555;
  .date {
    margin-right: 16px;
    color: #555;
    font-weight: 900;
    font-size: 18px;
  }
  .nav {
    font-size: 16px;
    display: inline-block;
    padding: 0 5px;
    border-radius: 1px;
    font-weight: 700;
    cursor: pointer;
    background: #efefef;
    color: #555;
    margin-right: 5px;
    border-radius: 5px;
  }
}

.scheduler-container {
  border: 1px solid #b3b3b3;
  position: relative;
  overflow: hidden;
  }
  .resources {
    position: absolute;
    width: 100%;
    z-index: 100;
    // pointer-events: none;
    .resource-row {
      color: #444;
      border-bottom: 1px solid #b3b3b3;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 12px;
      &:first-child {
        border-top: 1px solid #b3b3b3;
      }
      .resource-row-identity {
        pointer-events: all;
        display: flex;
        // position: absolute;
        // left: 0;
        // top: 0;
        height: 100%;
        justify-content: left;
        align-items: center;
        box-sizing: border-box;
        img {
          margin-right: 8px;
        }
        &.is-overview {
          cursor: pointer;
        }

        .identity {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          .resource-role {
            font-size: 11px;
            font-style: oblique;
          }
        }
      }
    }
  }
.timeline {
  height: 100%;
  display: flex;
  .resource-slot {
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    border-right: 1px solid #b3b3b3;
    box-sizing: border-box;
    background-color: #eee;
    color: black;
    font-size: 12px;
    text-indent: 5px;
    padding-top: 14px;
  }
  .timeline-slot {
    height: 100%;
    border-right: 1px solid #b3b3b3;
    box-sizing: border-box;
    color: black;
    font-size: 12px;
    text-indent: 5px;
    text-align: center;
    padding-top: 7px;
    font-weight: bold;
    &.is-today .cell {
      background: #135de6;
      font-weight: bold;
      color: #fff;
      padding-top: 7px;
      margin-top: -7px;
    }
    &.is-off {
      color: #f54f3d;
      background-color: #efefef
    }
    &.is-national-holiday,
    &.is-sunday,
    &.is-saturday {
      color: #f54f3d;
      background-color: #efefef
    }
  }
  &.fixed {
    pointer-events: none;
    touch-action: none;
    height: 40px !important;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 16px);
    padding-left: 8px;
    z-index: 1000;
    background-color: white;
    opacity: 0;
    transition: opacity 0.3s;
    border-bottom: 1px solid #CCC;
    .resource-slot {
      border-left: 1px solid #CCC;
    }
    &.displayed {
      opacity: 1;
    }
  }
}
.panel {
  .panel-detail {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    .panel-container {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-around;
      align-items: stretch;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 20px 30px 10px 30px;
      box-sizing: border-box;
      .panel-header {
        margin-bottom: 4px;
        color: #444;
        .panel-title {
          color: black;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 2px;
          a {
            color: black;
            text-decoration: none;
            span {
              position: relative;
              overflow: hidden;
              display: inline-block;
              vertical-align: bottom;
              svg {
                width: 13px;
                margin: 0 2px 0 2px;
                display: inline-block;
              }
              &:after {
                content: "\00A0";
                position: absolute;
                text-decoration: underline;
                right: 0;
                left: 0;
                bottom: 0px;
                top: inherit;
                letter-spacing: 1000px;
              }
            }
          }
        }
      }
      .panel-section-detail {
        position: relative;
        height: 100%;
        max-height: 100%;
        a {
          color: #696969;
          font-weight: bold;
          text-decoration: none;
          span {
            position: relative;
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            svg {
              width: 13px;
              margin: 0 2px 0 2px;
              display: inline-block;
            }
            &:after {
              content: "\00A0";
              position: absolute;
              text-decoration: underline;
              right: 0;
              left: 0;
              bottom: 0;
              top: 0;
              letter-spacing: 1000px;
            }
          }
        }
        .panel-detail-item {
          margin-top: 8px;
          margin-bottom: 8px;
          .panel-detail-item-title {
            font-size: 14px;
          }
          .panel-detail-item-description {
            font-size: 12px;
            background: #f3f3f396;
            padding: 8px;
            margin: 4px 0;
            border-radius: 4px;
            a {
              color: #135de6;
              font-weight: bold;
            }
            p, ul, ol {
              margin-top: 2px;
              margin-bottom: 2px;
            }

            *:first-child {
              margin-top: 0;
            }
            *:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.is-empty {
    opacity: 0;
  }
  &.has-no-description {
    .expand {
      display: none;
    }
  }
}
.tasks {
  width: 100%;
  height: 100%;
  z-index: 1;
  user-select: none;
  .task-row {
    position: relative;
    .task {
      --leftPosition: 0%;
      --widthSize: 0%;
      --paddingSize: 0px;
      --heightSize: 0px;
      --isVisible: 0;
      opacity: 1;
      position: absolute;
      top: 0px;
      box-sizing: border-box;
      cursor: pointer;
      left: var(--leftPosition);
      width: var(--widthSize);
      padding: var(--paddingSize);
      height: var(--heightSize);
      opacity: var(--isVisible);
      .cell {
        --backgroundColor: rgba(0, 0, 0, 0);
        background: var(--backgroundColor);
        position: relative;
        display: flex;
        border-radius: 2px;
        box-sizing: border-box;
        justify-content: left;
        font-size: 12px;
        z-index: 0;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: bold;
        border: 1px solid transparent;
        &.is-selected {
          border: 1px solid #3c3c3c;
          box-shadow: 0px 0px 4px 0px #3c3c3c8a;
          opacity: 0.95;
          .title {
            text-decoration: underline;
            text-underline-offset: 2px;
          }
        }
        &.is-stroke {
          --degree: 45deg;
          --primary-color: #000;
          --secondary-color: #000;
            background: repeating-linear-gradient(var(--degree), var(--primary-color), var(--primary-color) 6px, var(--secondary-color) 0px, var(--secondary-color) 12px);
        }
        &.is-dark {
          color: white;
          --colorDarkStroke: rgba(0, 0, 0, 0);
          text-shadow: 1px 1px var(--colorDarkStroke);
        }
        &.is-pending {
          opacity: 0.7;
          border: 1px dashed #6d0000;
        }
        &.is-bright {
          --colorBrightStroke: rgba(0, 0, 0, 0);
          color: black;
          text-shadow: 1px 1px var(--colorBrightStroke);
        }
        .title-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          position: relative;
          .title {
            width: 100%;
            flex-grow: 1;
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            .title-content {
              display: block;
              width: 100%;
              padding: 0px 4px;
              text-overflow: ellipsis;
              white-space: nowrap;
              box-sizing: border-box;
              overflow: hidden;
            }
          }
          .priority {
            width: 100%;
            flex-grow: 0;
            font-size: 9px;
            padding: 0;
            box-sizing: border-box;
            margin: 0;
            text-align: center;
            display: flex;
            gap: 1px;
            justify-content: space-around;
            align-items: center;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            .priority-slot {
              --backgroundColor: rgba(0, 0, 0, 0);
              --widthSize: auto;
              background-color: var(--backgroundColor);
              width: var(--widthSize);
              flex-grow: 1;
              &:first-child {
                  width: calc(var(--widthSize) - 3px);
                }

              &.only-one {
                width: var(--widthSize);
              }

            }
          }
        }
      }
    }
    &.workload {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
    }
  }
}

.ps__rail-x, .ps__rail-y {
    display: block;
    opacity: 0.6;
}
.workload-slot {
  font-size: 9px;
  font-weight: bold;
  box-sizing: border-box;
  border-bottom: 1px solid #b3b3b3;
  &.overload {
    color: red;
  }
}
</style>
