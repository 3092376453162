import _ from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'

import Calendar from '../views/Calendar.vue'
import Resources from '../views/Resources.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/resources'
  },
  {
    path: '/resources',
    name: 'Resources',
    props: (route) => {
      return {
        filter: _.get(route, 'query.filter', 'team'),
        department: _.get(route, 'query.department', false),
        team: _.get(route, 'query.team', false),
        employee: _.get(route, 'query.employee', false),
        project: _.get(route, 'query.project', false),
        mode: _.get(route, 'query.mode', false),
        start: _.get(route, 'query.start', false),
        end: _.get(route, 'query.end', false)
      }
    },
    component: Resources
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
